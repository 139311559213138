import "babel-polyfill";
import 'semantic-ui-css/semantic.min.css';
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore, compose} from "redux";
import { BrowserRouter as Router , Route } from 'react-router-dom';
import thunk from "redux-thunk";
import Keycloak from "keycloak-js";
import App from './App';
import './index.css'
import coreReducer from "./reducers";
import axios from "axios";
import keycloak from "./keycloak.json"
import HomeApp from "./components/home/HomeApp"
import Reports from "./components/reports/reports"
import './components/home/PagesApp.css';
import './components/home/certilytics.css'
import ErrorPage from "./components/ErrorPage"

const store = createStore(
  coreReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

const kcf = keycloak;
const kc = Keycloak(kcf);

kc.init({onLoad: 'check-sso'}).success(authenticated => {
  if (authenticated) {
    store.getState().keycloak = kc;

    /* taking this out - newer keycloaks this just bumps the token lifespan so timeout never occurs
    setInterval(() => {
      kc.updateToken(10).error(() => kc.logout());
    }, 10000);*/
    checkAutoLogout(kc);

    ReactDOM.render(app, document.getElementById("root"));
  } else {
    // show possibly other page here...
    {console.log("login")}
    kc.login();
  }
});

function checkAutoLogout(kc) {
  if (kc) {
      // check if token expired, if so, logout
      var expired = kc.isTokenExpired();
      
      if (expired === true) {
          kc.logout();
      }
      else {
          // calculate the full amount of seconds between now and when the users token expires
          var tokenExpiresInSecs = Math.round(kc.tokenParsed.exp + kc.timeSkew - new Date().getTime() / 1000);
          setTimeout(() => {
              checkAutoLogout(kc);
          },(tokenExpiresInSecs*1000));
      }
  }
}

axios.interceptors.request.use(config => {
  config.headers = {...config.headers, ...{
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + kc.token
  }};
  return config;
});

const app = (
  <Provider store={store}>
        <Router>
            <App kc = {kc}>
                <Route exact path="/" component={HomeApp}/>
                <Route exact path="/home" component={HomeApp}/>
                <Route exact path="/reports" component={Reports}/>
            </App>
        </Router>
    </Provider>
);