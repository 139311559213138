import React, { Component } from 'react';
import { connect } from 'react-redux';
import downloadIcon from './download.png';
import './reports.css';

class Reports extends Component {

  render() {
    const year = new Date().getFullYear();
    return (
      <html lang="en-US">
        <head>
          <link type="text/css" media="all" rel="stylesheet"/><title>Home - Certilytics</title>
        </head>
        <body class="home page-template-default page page-id-640 clearfix">
          <div class="page-header solid left">

            <div class="contain">
              <div class="luxy-el" data-speed-y="-15">
                <h2 style={{"color":"#011b3a"}}>
                  Reports
                </h2>			
                <div>
                  <div id="reportsTableContainer">
                  <table>
                      <tr>
                        <th>Report Category</th>
                        <th>Name of Report</th>
                        <th>Description</th>
                        <th>Download</th>
                      </tr>
                      <tbody>
                        <tr>
                          <td>Test Report</td>
                          <td>Test Doc 01 </td>
                          <td>The first test report, a Microsoft Word file. </td>
                          <td><a href={process.env.PUBLIC_URL + "reportfiles/test_doc_01.doc"}><img src={downloadIcon} /></a></td>
                        </tr>
                        <tr>
                          <td>Test Report</td>
                          <td>Test Doc 02 </td>
                          <td>The second test report, a Microsoft Excel file. </td>
                          <td><a href={process.env.PUBLIC_URL + "reportfiles/test_doc_02.xlsx"}><img src={downloadIcon} /></a></td>
                        </tr>
                        <tr>
                          <td>Test Report</td>
                          <td>Test Doc 03 </td>
                          <td>The third test report, a PDF file. </td>
                          <td><a href={process.env.PUBLIC_URL + "reportfiles/test_doc_03.pdf"}><img src={downloadIcon} /></a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>


                </div>			
              </div>
            </div>
          </div>
          <footer class="clearfix" style={{"padding":"10px 0px 10px"}}>			
            <div class="col">
              <span class="copyright">Copyright © 2020 Certilytics</span>
            </div>
          </footer>
        </body>
  </html>
);
}
}
function mapStateToProps(state) {
  return {
  
  }
}
export default connect(mapStateToProps)(Reports);