import React, { Component } from 'react';
import './ErrorPage.css'
import certilytics from '../img/certilytics.png'
import {Menu} from 'semantic-ui-react'
import MenuBar from './menu/menuBar'
import ThemeSwitch from './ThemeSwitch'

class ErrorPage extends Component {  

  render() {
    return (
        <div id='errorContainer'>
            <ThemeSwitch theme={this.props.theme}/>
            <MenuBar kc={this.props.kc} appAccess={this.props.appAccess} />
            <img id='ErrorImage' src={certilytics} alt='certilytics'/>
        </div>
      );
  }
}

export default ErrorPage;