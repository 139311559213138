function arrayRange(start, stop) {
  return Array.from({ length: stop }, (value, index) => start + index);
}

function getRanNumFrom(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function checkAllEmpty(elements, key = null) {
  return elements.every(element => (key ? !element[key] : !element));
}

function removeEmpty(elements, key = null) {
  const filteredArr = elements.reduce((acc, val) => {
    const value = key ? val[key] : val;
    if (value) acc = [...acc, val];
    return acc;
  }, []);

  return filteredArr;
}

// function createDictionaryByKey()

function getFusionTenant(kc) {
  const {
    realmAccess: { roles },
  } = kc;
  const filteredTenant =
    roles.filter(role => role.includes("_Fusion"))[0] ?? null;

  // stripping the role to get tenant
  const tenant = filteredTenant && filteredTenant.split("-")[1].split("_")[0]; // tenant comes from role with a structure like: <client> - <tenant>_FUSION
  if (tenant) return tenant;
}

function getClient(kc) {
  const {
    tokenParsed: { client },
  } = kc;
  const envClient = process.env.CLIENT ?? process.env.REACT_APP_CLIENT;
  return envClient || client;
}

export {
  arrayRange,
  getRanNumFrom,
  checkAllEmpty,
  removeEmpty,
  getClient,
  getFusionTenant,
};
