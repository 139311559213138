import React, { Component } from 'react';
import { connect } from 'react-redux';
import CertilyticsLogoIcons1 from '../menu/Certilytics-Logo-Icons-1.svg'
import CertilyticsLogoIcons2 from '../menu/Certilytics-Logo-Icons-2.svg'
import CertilyticsLogoIcons3 from '../menu/Certilytics-Logo-Icons-3.svg'
import CertilyticsLogoIcons4 from '../menu/Certilytics-Logo-Icons-4.svg'
import CertilyticsLogoIcons5 from '../menu/Certilytics-Logo-Icons-5.svg'
import CertilyticsLogoIcons6 from '../menu/Certilytics-Logo-Icons-6.svg'
import CertilyticsLogoIcons7 from '../menu/Certilytics-Logo-Icons-7.svg'
import CertilyticsLogoIcons8 from '../menu/Certilytics-Logo-Icons-8.svg'
import CertilyticsLogoIcons9 from '../menu/Certilytics-Logo-Icons-9.svg'
import CertilyticsLogoIcons10 from '../menu/Certilytics-Logo-Icons-10.svg'
import './certilytics.css'

class HomeApp extends Component {

  componentDidMount() {
    gtag('event', 'page_view', {
      page_title: 'Home', 
      page_location: window.location.href,
      page_path: window.location.pathname
    })
  }
  render() {
    const year = new Date().getFullYear();
    return (
      <html lang="en-US">
        <head>
          <link type="text/css" media="all" rel="stylesheet"/><title>Home - Certilytics</title>
        </head>
        <body class="home page-template-default page page-id-640 clearfix">
          <div class="page-header solid left">
            <div id="crossfade">
              <img src={CertilyticsLogoIcons1} alt="Certilytics-Logo-Icons-1"/>
              <img src={CertilyticsLogoIcons2} alt="Certilytics-Logo-Icons-2"/>
              <img src={CertilyticsLogoIcons3} alt="Certilytics-Logo-Icons-3"/>
              <img src={CertilyticsLogoIcons4} alt="Certilytics-Logo-Icons-4"/>
              <img src={CertilyticsLogoIcons5} alt="Certilytics-Logo-Icons-5"/>
              <img src={CertilyticsLogoIcons6} alt="Certilytics-Logo-Icons-6"/>
              <img src={CertilyticsLogoIcons7} alt="Certilytics-Logo-Icons-7"/>
              <img src={CertilyticsLogoIcons8} alt="Certilytics-Logo-Icons-8"/>
              <img src={CertilyticsLogoIcons9} alt="Certilytics-Logo-Icons-9"/>
              <img src={CertilyticsLogoIcons10} alt="Certilytics-Logo-Icons-10"/>
            </div>
            <div class="contain">
              <div class="box luxy-el" data-speed-y="-15">
                <h1 style={{"color":"#011b3a"}}>
                  Proven.<br/>Powerful.<br/>Efficient.	
                </h1>			
                <div>
                  <p style={{"color":"#011b3a"}}>Our AI-powered products allow clients to see the future through data, enabling them to thrive in today’s ever-changing, value-focused healthcare market.</p>
                </div>			
              </div>
            </div>
          </div>
          <footer class="clearfix" style={{"padding":"10px 0px 10px"}}>			
            <div class="col">
              <span class="copyright">Copyright © {new Date().getFullYear()} Certilytics</span>
            </div>
          </footer>
        </body>
  </html>
);
}
}
function mapStateToProps(state) {
  return {
  
  }
}
export default connect(mapStateToProps)(HomeApp);