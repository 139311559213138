import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchClientTheme,
  fetchClientApplications,
  getClientRoleName,
} from "./actions/getClientProperties";
import MenuBar from "./components/menu/menuBar";
import ErrorPage from "./components/ErrorPage";
import ThemeSwitch from "./components/ThemeSwitch";
import "./App.css";
import theme1 from "./components/menu/theme1.png";
import theme2 from "./components/menu/theme2.png";
import theme4 from "./components/menu/theme4.png";
import theme5 from "./components/menu/theme5.png";
import theme6 from "./components/menu/theme6.png";
import theme7 from "./components/menu/theme7.png";
import certilyticsa from "./components/menu/certilyticsa.png";
import certilyticsa1 from "./components/menu/certilyticsa-1.png";
import certilyticsa2 from "./components/menu/certilyticsa-2.png";
import certilyticsa3 from "./components/menu/certilyticsa-3.png";
import certilyticsa4 from "./components/menu/certilyticsa-4.png";
import certilyticsa5 from "./components/menu/certilyticsa-5.png";
import certilyticsa6 from "./components/menu/certilyticsa-6.png";
import certilyticsa7 from "./components/menu/certilyticsa-7.png";
import certilyticsa8 from "./components/menu/certilyticsa-8.png";
import certilyticsa9 from "./components/menu/certilyticsa-9.png";
import certilyticsa10 from "./components/menu/certilyticsa-10.png";

class App extends Component {
  state = {
    loading: true,
    logo: "",
    theme: "",
    access: false,
    appAccess: [],
  };

  componentDidMount() {
    var client = process.env.CLIENT
      ? process.env.CLIENT
      : process.env.REACT_APP_CLIENT
      ? process.env.REACT_APP_CLIENT
      : this.props.kc.tokenParsed.client
      ? this.props.kc.tokenParsed.client
      : "";
    var realmRoles = this.props.kc.realmAccess.roles;
    var clientname = realmRoles.filter(element =>
      element.includes(client + "-")
    );
    var clientRoleName = clientname[0];

    // check for user's theme
    if (this.props.kc.tokenParsed.hasOwnProperty("theme")) {
      this.setState({
        theme: this.props.kc.tokenParsed.theme,
      });

      this.checkApplicationsAccess(clientRoleName);
    } else if (clientRoleName != undefined && clientRoleName != "") {
      // send realm level client config role for user, to get theme
      this.props.fetchClientTheme(clientRoleName).then(r => {
        if (r.error) {
          this.setNoAccess();
        } else {
          this.setState({
            theme: r,
            clientRoleName: clientRoleName,
          });

          this.checkApplicationsAccess(clientRoleName);
        }
      });
    } else {
      this.setNoAccess();
    }
  }

  checkApplicationsAccess(clientRoleName) {
    var accessToHome = false;
    if (
      this.props.kc.resourceAccess.hasOwnProperty("cap-home-web") &&
      this.props.kc.resourceAccess["cap-home-web"].roles.includes("home")
    ) {
      accessToHome = true;
    }
    // always call client config api to get all applications user may have access to
    if (clientRoleName != undefined && clientRoleName != "") {
      // send realm level client config role for user, to get apps user has access to
      this.props.fetchClientApplications(clientRoleName).then(r => {
        if (!accessToHome && r.includes("Home")) {
          accessToHome = true;
        }
        this.setState({
          access: accessToHome,
          appAccess: r,
        });

        setTimeout(() => this.setState({ loading: false }), 1500);
        if (this.state.loading) {
          setInterval(() => this.setState({ logo: this.setLogo() }), 1000);
        }
      });
    } else if (accessToHome) {
      this.setState({
        access: accessToHome,
        appAccess: [],
      });

      setTimeout(() => this.setState({ loading: false }), 1500);
      if (this.state.loading) {
        setInterval(() => this.setState({ logo: this.setLogo() }), 1000);
      }
    } else {
      this.setNoAccess();
    }
  }

  setNoAccess() {
    // no realm level client config role found for user
    this.setState({
      access: false,
      loading: false,
    });
    console.error("User requires one Realm level Role starting with CLIENT-");
  }

  setLogo() {
    if (this.state.theme == "theme1") {
      return "theme1";
    } else if (this.state.theme == "theme4") {
      return "theme4";
    } else if (this.state.theme == "theme2") {
      return "theme2";
    } else if (this.state.theme == "theme5") {
      return "theme5";
    } else if (this.state.theme == "theme6") {
      return "theme6";
    } else if (this.state.theme == "theme7") {
      return "theme7";
    } else {
      var random = Math.random();
      if (Math.floor(random * 10) === 0) {
        return "certilyticsa1";
      }
      if (Math.floor(random * 10) === 1) {
        return "certilyticsa2";
      } else if (Math.floor(random * 10) === 2) {
        return "certilyticsa3";
      } else if (Math.floor(random * 10) === 3) {
        return "certilyticsa4";
      } else if (Math.floor(random * 10) === 4) {
        return "certilyticsa5";
      } else if (Math.floor(random * 10) === 5) {
        return "certilyticsa6";
      } else if (Math.floor(random * 10) === 6) {
        return "certilyticsa7";
      } else if (Math.floor(random * 10) === 7) {
        return "certilyticsa8";
      } else if (Math.floor(random * 10) === 8) {
        return "certilyticsa9";
      } else if (Math.floor(random * 10) === 9) {
        return "certilyticsa10";
      } else {
        return "certilyticsa";
      }
    }
  }

  getImage() {
    if (this.state.logo == "theme1") {
      return theme1;
    } else if (this.state.logo == "theme4") {
      return theme4;
    } else if (this.state.logo == "theme2") {
      return theme2;
    } else if (this.state.logo == "theme3") {
      return certilyticsa3;
    } else if (this.state.logo == "theme5") {
      return theme5;
    } else if (this.state.logo == "theme6") {
      return theme6;
    } else if (this.state.logo == "theme7") {
      return theme7;
    } else if (this.state.logo == "certilyticsa1") {
      return certilyticsa1;
    } else if (this.state.logo == "certilyticsa2") {
      return certilyticsa2;
    } else if (this.state.logo == "certilyticsa3") {
      return certilyticsa3;
    } else if (this.state.logo == "certilyticsa4") {
      return certilyticsa4;
    } else if (this.state.logo == "certilyticsa5") {
      return certilyticsa5;
    } else if (this.state.logo == "certilyticsa6") {
      return certilyticsa6;
    } else if (this.state.logo == "certilyticsa7") {
      return certilyticsa7;
    } else if (this.state.logo == "certilyticsa8") {
      return certilyticsa8;
    } else if (this.state.logo == "certilyticsa9") {
      return certilyticsa9;
    } else if (this.state.logo == "certilyticsa10") {
      return certilyticsa10;
    } else if (this.state.logo == "certilyticsa") {
      return certilyticsa;
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ "padding-top": "100px" }}>
          {this.state.logo == "theme5" ? (
            <img
              style={{ "padding-left": "calc(50% - 75px)" }}
              src={this.getImage()}
              height="50"
            />
          ) : (
            <img
              style={{ "padding-left": "calc(50% - 100px)" }}
              src={this.getImage()}
              height="35"
              alt="Certilytics"
            />
          )}
          <div style={{ "text-align": "center" }} class="loader">
            Hold tight -- we're authenticating your access now!
          </div>
        </div>
      );
    } else {
      return (
        <html>
          <div className="main-container">
            {this.state.access ? (
              <div>
                <ThemeSwitch theme={this.state.theme} />
                <MenuBar
                  kc={this.props.kc}
                  theme={this.state.theme}
                  appAccess={this.state.appAccess}
                />
                {this.props.children}
              </div>
            ) : (
              <ErrorPage
                kc={this.props.kc}
                theme={this.state.theme}
                appAccess={this.state.appAccess}
              />
            )}
          </div>
        </html>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    data: state.data,
  };
}

App.propTypes = {
  fetchClientTheme: PropTypes.func.isRequired,
  fetchClientApplications: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, { fetchClientTheme, fetchClientApplications })(App)
);
