import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import keycloak from "./keycloak";

const coreState = combineReducers({
  routing: routerReducer,
  keycloak
});

export default coreState;
