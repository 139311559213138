import axios from 'axios';

export function fetchClientApplications(clientRoleName){
    return (dispatch, getState) => {
        var prefix = (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local' ? 'http://msp2-devapp13:8089' : '');
        var url = prefix+'/clientconfigapi/v1/findApplicationsByClientRole';

        return axios.post(url,clientRoleName).then( res => {
            return res.data
        });
    }  
}

export function fetchClientTheme(clientRoleName){
    return (dispatch, getState) => {
        var prefix = (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local' ? 'http://msp2-devapp13:8089' : '');
        var url = prefix+'/clientconfigapi/v1/findThemeByClientRole';

        return axios.post(url,clientRoleName).then( res => {
            return res.data
        })
        .catch(err => { // this call returns 500 error if clientRoleName is not found in DB
            if (err.response) {
              // client received an error response (5xx, 4xx)
              return {
                  error: "apiError",
                  code: err.response.status || "n/a",
                  message: err.response.data.error || "n/a"
              }
            }
        });
    }  
}