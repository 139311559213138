import navProps from "../../../navigation.json";

function getSubDomain(client, app, moreOptions, tenant = "") {
  const subDomain = `${client}.cloud.certilytics.com`;
  if (tenant) return `https://${app}-${tenant}.${subDomain}${moreOptions}`;
  return `https://${app}.${subDomain}${moreOptions}`;
}

function createMenuUrl(app, client, moreOptions = "") {
  const generatedUrl = getSubDomain(client, app, moreOptions);
  const navPropsUrl = navProps[app];
  if (navPropsUrl) return navPropsUrl.replace("CLIENT", client);
  return generatedUrl;
}

function createTenantUrl(tenant, app, client, moreOptions = "") {
  const generatedUrl = getSubDomain(client, app, moreOptions, tenant);
  if (tenant) return generatedUrl;
  return createMenuUrl(app, client);
}

function getMenuUrls(client, tenants) {
  const fusionUrl = createTenantUrl(tenants.fusion, "fusion", client);
  const datamapUrl = createMenuUrl(
    "datacatalogue",
    client,
    "/user/login?came_from=/home"
  );
  const brainstormUrl = createMenuUrl("brainstorm", client);
  return {
    fusion_url: `${fusionUrl}/auth/sso/?redirect=${encodeURI(
      fusionUrl
    ).toLocaleLowerCase()}`,
    datamap_url: datamapUrl,
    brainstorm_url: brainstormUrl,
  };
}

export { getMenuUrls as default };
