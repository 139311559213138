import React, { Component } from 'react';

class ThemeSwitch extends Component {  
    constructor(props) {  
        super(props);

        var themes = {
            theme1: getTheme1(), 
            theme2: getTheme2(),
            theme3: getTheme3(),
            theme4: getTheme4()
        };

        this.state = {
            theme: props.theme,
            themeCss: setTheme(themes,props.theme)
        };
    }

    render() {
        return (  
            <div style={{display:'none'}}>
            <style media='screen'>
                {this.state.theme.trim()}
            </style>
            </div>
        );
    }
}

function setTheme(themes,theme){
    if(themes.hasOwnProperty(theme)){
        return(themes[theme])
    }
    else if(theme == "theme1"){
        return(themes['theme1'])
    }
    else if(theme == "theme2"){
        return(themes['theme2'])
    }
    else if(theme == "theme3"){
        return(themes['theme3'])
    }
    else if(theme == "theme4"){
        return(themes['theme4'])
    }
    else
    {
        return(themes['theme3'])
    }
}

function getTheme1(){
    return(
    `
    .ui.menu .active.item{
        background-color: #e1ebff;
    }
    
    .submenubar div.ui.menu{
        background-color:#0079c2;
    }
    
    button.ui.button.grey, button.ui.button.grey:hover, button.ui.button.grey:focus{
        background-color:#005eac;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        background-color:white;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        color:#005eac;
    }
    
    .ui.basic.very.padded.segment a, .ui.basic.very.padded.segment a:focus, .ui.basic.very.padded.segment a:hover, .ui.basic.very.padded.segment .active.item > a{
        color:black;
        background-color:white;
    }
    
    .ui.basic.segment {
        background-color:white;
    }
    
    html{
        background-color:white;
    }
    `
    )
}

function getTheme4(){
    return(
    `
    .submenubar div.ui.menu{
        background-color:#009bdf;
    }
    
    button.ui.button.grey, button.ui.button.grey:hover, button.ui.button.grey:focus{
        background-color:#14568d;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        background-color:white;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        color:#14568d;
    }
    
    .ui.basic.very.padded.segment a, .ui.basic.very.padded.segment a:focus, .ui.basic.very.padded.segment a:hover, .ui.basic.very.padded.segment .active.item > a{
        color:black;
        background-color:white;
    }
    
    .ui.basic.segment {
        background-color:white;
    }
    
    html{
        background-color:white;
    }
    `
    )
}

function getTheme2(){
    return(
    `
    .submenubar div.ui.menu{
        background-color:#009bdf;
    }
    
    button.ui.button.grey, button.ui.button.grey:hover, button.ui.button.grey:focus{
        background-color:#14568d;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        background-color:white;
    }
    
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        color:#14568d;
    }
    
    .ui.basic.very.padded.segment a, .ui.basic.very.padded.segment a:focus, .ui.basic.very.padded.segment a:hover, .ui.basic.very.padded.segment .active.item > a{
        color:black;
        background-color:white;
    }
    
    .ui.basic.segment {
        background-color:white;
    }
    
    html{
        background-color:white;
    }
    `
    )
}

function getTheme3(){
    return(
    `
    i.icon.help{
        color: #f4695b;
    }
    .submenubar div.ui.menu{
        background: #e1ebff;
    }
    .ui.menu .active.item{
        background-color: #e1ebff;
    }
    button.ui.orange.button, button.ui.orange.button:hover, button.ui.orange.button:focus{
        background-color:#001c3b;
        color:white;
    }
    button.ui.grey.button, button.ui.grey.button:hover, button.ui.grey.button:focus{
        background-color:#5e5e5e;
        color:white;
    }
    .ui.text.menu .active.item > a{
        border-bottom: 1px solid #1c54de
        ;
    }
    #menulevel2 .ui.text.menu .active.item > a{
        border-bottom: 1px solid #1c54de
        ;
    }
    a, a:focus, a:hover{
        color: #5e5e5e;
    }
    `
    )
}

export default ThemeSwitch;