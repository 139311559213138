import React, { Component } from "react";
import { Menu, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styles from "./menuBar.css";
import { connect } from "react-redux";
import theme1 from "./theme1.png";
import theme2 from "./theme2.png";
import theme4 from "./theme4.png";
import theme5 from "./theme5.png";
import theme6 from "./theme6.png";
import theme7 from "./theme7.png";
import certilyticsa from "./certilyticsa.png";
import certilyticsa1 from "./certilyticsa-1.png";
import certilyticsa2 from "./certilyticsa-2.png";
import certilyticsa3 from "./certilyticsa-3.png";
import certilyticsa4 from "./certilyticsa-4.png";
import certilyticsa5 from "./certilyticsa-5.png";
import certilyticsa6 from "./certilyticsa-6.png";
import certilyticsa7 from "./certilyticsa-7.png";
import certilyticsa8 from "./certilyticsa-8.png";
import certilyticsa9 from "./certilyticsa-9.png";
import certilyticsa10 from "./certilyticsa-10.png";
import { getClient, getFusionTenant } from "../../utils/utilities";
import getMenuUrls from "./utils/menuUtils";

class MenuBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: "Home",
    };
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const kc = this.props.kc;
    let view_aspects,
      view_aspectsdashboards,
      view_coreui,
      view_admin,
      view_imrx,
      view_reports,
      view_brainstorm,
      view_fusion,
      view_datamap = false;
    // Replace CLIENT in nav URLs, i.e. https://<APP>.CLIENT.cloud.certilytics.com
    // Order of precedence for replacement:
    // 1. DevOps deploy process does variable replacement so CLIENT becomes the proper value
    // 2. REACT_APP_CLIENT is passed in as env variable, use that
    // 3. Keycloak user attribute "client" exists, use that
    const tenants = { fusion: getFusionTenant(kc) };
    const client = getClient(kc);
    const { fusion_url, datamap_url, brainstorm_url } = getMenuUrls(
      client,
      tenants
    );

    if (
      (this.props.kc.resourceAccess.hasOwnProperty("ci-aspects-web") &&
        this.props.kc.resourceAccess["ci-aspects-web"].roles.includes(
          "aspects"
        )) ||
      this.props.appAccess.includes("Aspects v3")
    ) {
      view_aspects = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty(
        "ci-aspects-dashboards-web"
      ) &&
        this.props.kc.resourceAccess[
          "ci-aspects-dashboards-web"
        ].roles.includes("aspects-dashboards")) ||
      this.props.appAccess.includes("Aspects v4")
    ) {
      view_aspectsdashboards = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-admin-web") &&
        this.props.kc.resourceAccess["cap-admin-web"].roles.includes(
          "admin"
        )) ||
      this.props.appAccess.includes("Admin")
    ) {
      view_admin = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-core-ui-web") &&
        this.props.kc.resourceAccess["cap-core-ui-web"].roles.includes(
          "coreui"
        )) ||
      this.props.appAccess.includes("Core Report")
    ) {
      view_coreui = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-imrx-web") &&
        this.props.kc.resourceAccess["cap-imrx-web"].roles.includes("imrx")) ||
      this.props.appAccess.includes("IMRx")
    ) {
      view_imrx = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-brainstorm-web") &&
        this.props.kc.resourceAccess["cap-brainstorm-web"].roles.includes(
          "brainstorm"
        )) ||
      this.props.appAccess.includes("Brainstorm")
    ) {
      view_brainstorm = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-fusion-web") &&
        this.props.kc.resourceAccess["cap-fusion-web"].roles.includes(
          "fusion"
        )) ||
      this.props.appAccess.includes("Fusion")
    ) {
      view_fusion = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-datamap-web") &&
        this.props.kc.resourceAccess["cap-datamap-web"].roles.includes(
          "datamap"
        )) ||
      this.props.appAccess.includes("Datamap")
    ) {
      view_datamap = true;
    }
    if (
      (this.props.kc.resourceAccess.hasOwnProperty("cap-reports-web") &&
        this.props.kc.resourceAccess["cap-reports-web"].roles.includes(
          "reports"
        )) ||
      this.props.appAccess.includes("Aspects Reports")
    ) {
      view_reports = true;
    }

    let { activeItem } = this.state;
    if (window.location.pathname.indexOf("/reports") > -1) {
      activeItem = "Reports";
    }

    const style = { "text-transform": "capitalize" };
    const logo = setLogo(this.props.theme);

    return (
      <Menu className="menubar">
        <Menu.Item style={{ "background-color": "white" }}>
          <Link to="/home/" href="#" onClick={this.handleItemClick}>
            {" "}
            <img src={logo} height="35" alt="Certilytics" />
          </Link>
        </Menu.Item>
        <Menu.Item
          name="Home"
          title="Home"
          className="not_hidden"
          href="/home/"
          active={activeItem === "Home"}
          onClick={this.handleItemClick}
        >
          Home
        </Menu.Item>
        {view_admin && (
          <Menu.Item name="Admin" title="Admin" href="admin/">
            Admin
          </Menu.Item>
        )}
        {view_coreui && (
          <Menu.Item name="Core" title="Core Report" href="corereport/">
            CORE Report
          </Menu.Item>
        )}
        {view_aspects && (
          <Menu.Item name="aspects" title="Aspects" href="/aspects/">
            Aspects
          </Menu.Item>
        )}
        {view_aspectsdashboards && (
          <Menu.Item
            name="aspectsdashboards"
            title="Aspects Dashboards"
            href="/aspectsdashboards/"
          >
            Aspects Dashboards
          </Menu.Item>
        )}
        {view_brainstorm && (
          <Menu.Item
            name="brainstorm"
            title="Brainstorm"
            href={brainstorm_url}
            target="_blank"
          >
            Brainstorm<sup>&reg;</sup> AI Studio
          </Menu.Item>
        )}
        {view_fusion && (
          <Menu.Item
            name="fusion"
            title="Fusion"
            href={fusion_url}
            target="_blank"
          >
            Fusion
          </Menu.Item>
        )}
        {view_datamap && (
          <Menu.Item
            name="datamap"
            title="Data Catalogue"
            href={datamap_url}
            target="_blank"
          >
            Data Catalogue
          </Menu.Item>
        )}
        {view_imrx && (
          <Menu.Item name="IMRx" title="IMRx" href="/imrx/">
            IMRx
          </Menu.Item>
        )}
        {view_reports && (
          <Menu.Item
            name="Reports"
            title="Aspects Reports"
            href="/reports/"
            active={activeItem === "Reports"}
            onClick={this.handleItemClick}
          >
            Aspects Reports
          </Menu.Item>
        )}
        <Menu.Menu position="right">
          <Menu.Item style={style}>
            Welcome, {kc.tokenParsed.given_name}&nbsp;
          </Menu.Item>
          <Menu.Item
            name="logout"
            style={{ height: "64px" }}
            active={activeItem === "logout"}
            onClick={kc.logout}
          />
          <Menu.Item name="help"> </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

const mapStateToProps = state => {
  return {
    kc: state.keycloak,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

function refreshPage() {
  window.location.reload();
}

function setLogo(theme) {
  if (theme === "theme1") {
    const logo = theme1;
    return logo;
  } else if (theme === "theme1cii") {
    const logo = theme1;
    return logo;
  } else if (theme === "theme4") {
    const logo = theme4;
    return logo;
  } else if (theme === "theme2") {
    const logo = theme2;
    return logo;
  } else if (theme === "theme5") {
    const logo = theme5;
    return logo;
  } else if (theme === "theme6") {
    const logo = theme6;
    return logo;
  } else if (theme === "theme7") {
    const logo = theme7;
    return logo;
  } else {
    var logo = certilyticsa3;
    /*
      var logo = certilyticsa1;
      if(Math.floor(Math.random() * 10) === 1){
        logo = certilyticsa2;
      }
      else if(Math.floor(Math.random() * 10) === 2){
        logo = certilyticsa3;
      }
      else if(Math.floor(Math.random() * 10) === 3){
        logo = certilyticsa4;
      }
      else if(Math.floor(Math.random() * 10) === 4){
        logo = certilyticsa5;
      }
      else if(Math.floor(Math.random() * 10) === 5){
        logo = certilyticsa6;
      }
      else if(Math.floor(Math.random() * 10) === 6){
        logo = certilyticsa7;
      }
      else if(Math.floor(Math.random() * 10) === 7){
        logo = certilyticsa8;
      }
      else if(Math.floor(Math.random() * 10) === 8){
        logo = certilyticsa9;
      }
      else{
        logo = certilyticsa10;
      } 
      */
    return logo;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
